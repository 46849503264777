<template>
  <div id="app" class="app">
    <router-view />
    <actionSnackBar></actionSnackBar>
  </div>
</template>

<script>
import actionSnackBar   from '@/tools/VSnackBar.vue'
import VueSanitize      from "vue-sanitize"

import Vue from 'vue'

Vue.use(VueSanitize)

export default {
  name: 'App',
    components: {
      actionSnackBar
    },
}
</script>

<style lang="scss">
/* styles */
    .app {
        width:100%;
        height:100%;
        margin:0;
        padding:0;
    }
</style>