import axios from 'axios';
import { Store } from '../tools/Store.js';

const envParams = {
    "dev": {
        urlBE: "https://www.gpt-conseil.com/be",
        "prefixPostMessage": "vb_pm_chat_"
    },
    "prod": {
        urlBE: "https://www.gpt-conseil.com/be",
        "prefixPostMessage": "vb_pm_chat_"
    }
};


const state = {
    mode: 'prod',
    envParams: {},
    idChat: "",
    chatStatus: "CREATED",
    idChatTemplate: "",
    messages: [],
    waitingMessages: [],
    lastTS: 0,
    isBotTyping: false,
    waitAnswer: false,
    customData: {},
    refUser: null,
    lockGetMessages: false,
    chatClosed: false,
    showChatClosed: false,
    tagline: [
        'Parlons ensemble de comment augmenter vos ventes en ligne, je suis dispo..',
        'Vous êtes web entrepreneur ? J’ai une excellente nouvelle pour augmenter vos ventes !',
        'Vous croyez que je ne peux pas comprendre vos besoins et y répondre ? Chiche, parlons ensemble deux minutes !...'
    ],
    profileImgUrl: "",
    userProfileImgUrl: "/assets/profileUser.png",
    css: {},

    chatParams: {
        speedTyping: 130,
        chatRetrieveType: "never",
        idChatTemplate: "",
        messageSplit: { type: "SPLIT_PER_WORDS", nbChar: 47 },
        assistantName: "",
        profileImgUrl: "",
        css: {}
    },
    templatingData: {},
    doMaxSpeed: false,

    chatInitialized: false,
};

const getters = {
    idChatTemplate: state => state.idChatTemplate,
    idChat: state => state.idChat,
    messages: state => state.messages,
    mode: state => state.mode,
    envParams: state => state.envParams,
    chatStatus: state => state.chatStatus,
    waitingMessages: state => state.waitingMessages,
    isBotTyping: state => state.isBotTyping,

    lastTS: state => state.lastTS,
    waitAnswer: state => state.waitAnswer,
    customData: state => state.customData,
    refUser: state => state.refUser,
    lockGetMessages: state => state.lockGetMessages,
    chatClosed: state => state.chatClosed,
    showChatClosed: state => state.showChatClosed,

    profileImgUrl: state => state.profileImgUrl,
    userProfileImgUrl: state => state.userProfileImgUrl,
    css: state => state.css,

    tagline: state => state.tagline,

    chatParams: state => state.chatParams,
    templatinData: state => state.templatinData,
    speedTyping: state => state.chatParams.speedTyping,
    chatRetrieveType: state => state.chatParams.chatRetrieveType,
    chatInitialized: state => state.chatInitialized,
    messageSplit: (state) => {
        if (Object.prototype.hasOwnProperty.call(state.chatParams, "messageSplit")) {
            return state.chatParams.messageSplit;
        } else {
            return { type: "SPLIT_PER_WORDS", nbChar: 47 };
        }
    },
    doMaxSpeed: state => state.doMaxSpeed,

    templateT: (state) => (message) => {
        try {
            var templatedText = message;
            var customData = state.templatingData;
            var upperCase = false;
            var toReplace = null;
            var keyToUse = null;
            for (const key of Object.keys(customData)) {
                keyToUse = (upperCase ? key.toUpperCase() : key);

                toReplace = new RegExp("__" + keyToUse + "__", 'g');
                templatedText = templatedText.replace(toReplace, customData[key]);
            }

        } catch (err) {
            console.debug(err);
        }
        return templatedText;
    },
};

const mutations = {
    addMessage(state, message) {
        state.messages.push(message);
        state.lastTS = Date.now();
    },
    userToken: (state, data) => { state.userToken = data },
    idChat: (state, data) => { state.idChat = data },
    envParams: (state, data) => { state.envParams = data },
    chatStatus: (state, data) => { state.chatStatus = data },
    idChatTemplate: (state, data) => { state.idChatTemplate = data },
    speedTyping: (state, data) => { state.chatParams.speedTyping = data },
    waitAnswer: (state, data) => { state.waitAnswer = data },
    customData: (state, data) => { state.customData = data },
    refUser: (state, data) => { state.refUser = data },
    lockGetMessages: (state, data) => { state.lockGetMessages = data },
    chatClosed: (state, data) => { state.chatClosed = data },
    showChatClosed: (state, data) => { state.showChatClosed = data },
    profileImgUrl: (state, data) => { state.profileImgUrl = data },
    css: (state, data) => { state.css = data },

    chatParams: (state, data) => { state.chatParams = data },
    templatingData: (state, data) => { state.templatingData = data },
    doMaxSpeed: (state, data) => { state.doMaxSpeed = data },
    chatInitialized: (state, data) => { state.chatInitialized = data },

    resetMessages(state) {
        state.messages = [];
        state.lastTS = 0;
        state.waitAnswer = false;
    },

};

const actions = {
    initApp({ commit, getters }) {
        commit("envParams", envParams[getters["mode"]]);
    },

    async createChat({ commit, dispatch, state, getters }, idChatTemplate) {
        state.waitAnswer = true;
        const response = await axios.post(getters["envParams"].urlBE + '/chat/create',
            {
                idCT: idChatTemplate,
                idCType: "GOAL_COACH",
                customData: state.customData
            });

        if (response.data.isSuccess) {
            await dispatch("startChat", response.data.data.idChat);
            commit('idChat', response.data.data.idChat);
        }
    },

    async getChatParams({ commit, state, getters, dispatch }, idChat) {
        const response = await axios.post(getters["envParams"].urlBE + '/chat/getChatParams',
            {
                idChat: idChat
            });

        if (response.data.isSuccess) {
            var chatParams = response.data.data;
            await dispatch("setChatParams", chatParams);
        }
    },

    async getTemplateParams({ commit, state, getters, dispatch }, idChatTemplate) {
        const response = await axios.post(getters["envParams"].urlBE + '/chat/getTemplateParams',
            {
                idCT: idChatTemplate
            });

        if (response.data.isSuccess) {
            var chatParams = response.data.data;
            await dispatch("setChatParams", chatParams);
        }
    },

    async setChatParams({ commit, state, getters, dispatch }, chatParams) {
        if (Object.prototype.hasOwnProperty.call(chatParams, "messageSplit")) {
            let nbChar = chatParams.messageSplit.nbChar;
            nbChar = (typeof nbChar === 'string' || nbChar instanceof String ? parseInt(nbChar) : nbChar);
            chatParams.messageSplit.nbChar = nbChar;
        } else {
            chatParams.messageSplit = { type: "SPLIT_PER_WORDS", nbChar: 47 };
        }

        commit('chatParams', chatParams);

        console.debug("chatParams : ", chatParams);

        await dispatch("initUI");
        await dispatch("initTemplating");

        commit("chatInitialized", true);
    },

    async retrieveChat({ commit, dispatch, state }, idChat) {
        state.waitAnswer = true;

        if (idChat != null && idChat != "") {
            await dispatch("startChat", idChat);
            commit('idChat', idChat);
        }
    },
    /**
     * call BE sendMessage, if result ok => add idMessage in message and store it in message list state.messages
     **/
    async sendMessage({ commit, state, getters }, message) {
        state.waitAnswer = true;

        commit("lockGetMessages", true);

        const response = await axios.post(getters["envParams"].urlBE + '/chat/sendMessage', {
            idChat: state.idChat,
            message: message.message
        });


        if (response.data.isSuccess) {
            message.idMessage = response.data.data;
            commit('addMessage', message);
        }

        commit("lockGetMessages", false);
    },

    async getMessages({ commit, dispatch, state, getters }) {
        var lastMessage = "";
        var idMessage = "";

        if (state.idChat == "" || state.lockGetMessages) {
            return;
        }

        commit("lockGetMessages", true);

        try {
            lastMessage = state.messages[state.messages.length - 1];
            if (typeof lastMessage != "undefined") {
                idMessage = lastMessage.idMessage;
            }
        } catch (err) {
            console.debug("getMessages 1 error : ", err);
        }

        try {
            const response = await axios.post(getters["envParams"].urlBE + '/chat/getMessages', {
                idChat: state.idChat,
                idMessage: idMessage
            });
            var message = null;

            if (response.data.isSuccess && response.data.data != null
                && response.data.data.messages.length > 0) {
                for (message of response.data.data.messages) {
                    if (message.type == "10") {
                        try {
                            message.action = JSON.parse(message.message);
                        } catch (err) {
                            message.action = {};
                        }
                        actions.processAction({ commit, dispatch, state }, message);
                    } else {
                        if (message.type == "2") {
                            message.fromUser = true;
                        } else {
                            message.fromBot = true;
                        }
                    }
                    commit('addMessage', message);
                }

                if (message.fromBot) {
                    commit("waitAnswer", false);
                }
                if (message.fromUser) {
                    commit("waitAnswer", true);
                }
            }

        } catch (err) {
            console.debug("getMessages 2 error : ", err);
        }

        commit("lockGetMessages", false);
    },
    async processAction({ commit, dispatch, state }, message) {

        try {
            switch (message.action.action) {
                case "LOCK_BY_COOKIE":
                    Store.set(Store.createKey("cic", message.action.data), false);
                    break;
                case "CLOSE":
                    commit("chatClosed", true);
                    if (Object.prototype.hasOwnProperty.call(message.action, "chatData")) {
                        dispatch("sendChatDataToParent", message.action.chatData);
                    }
                    dispatch("closeChat");
                    break;
                case "BUTTON":
                case "CMD":
                    break;
                default:
                    console.debug("action received : ", message.action);
                    if (Object.prototype.hasOwnProperty.call(message.action, "js")) {
                        dispatch("sendJSCode", message.action.js);
                    }
            }
        } catch (err) {
            console.debug("processAction error : ", err);
        }
    },

    /**
     * Start chat.
     * The chat has to be created in BE and this method needs the idChat to be initialised
     **/
    async startChat({ commit, dispatch, state }, idChat) {
        try {
            await dispatch("getChatParams", idChat);
            await dispatch("actionBeforeStartChat", idChat);
            commit("chatStatus", "STARTED");
        } catch (err) {
            console.debug("startChat error : ", err);
        }
    },

    /**
     * Action before Start chat.
     * The chat has to be created in BE and this method needs the idChat to be initialised
     **/
    async actionBeforeStartChat({ commit, dispatch, getters, state }, idChat) {
        try {
            if (getters["chatRetrieveType"] == "ALWAYS") {
                var tsExpires = new Date();
                tsExpires.setDate(tsExpires.getDate() + 2);
                dispatch("setHavePreviousChat", {
                    refUser: getters["refUser"],
                    idChatTemplate: getters["idChatTemplate"],
                    idChat: idChat,
                    tsExpires: tsExpires.toISOString()
                });
                //            Store.set(Store.createKey("idc", getters["idChatTemplate"]), idChat.toString(), "2d");

            }
        } catch (err) {
            console.debug("startChat error : ", err);
        }
    },

    sendChatDataToParent({ getters }, chatData) {
        var prefixPostMessage = getters["envParams"].prefixPostMessage;

        var data = {
            action: prefixPostMessage + "sendDataToParent",
            customData: chatData
        };

        window.parent.postMessage(data, '*');
    },
    closeChat({ getters }) {
        var prefixPostMessage = getters["envParams"].prefixPostMessage;

        var data = {
            action: prefixPostMessage + "closeChat"
        };

        window.parent.postMessage(data, '*');
    },
    sendJSCode({ getters }, jsCode) {
        var prefixPostMessage = getters["envParams"].prefixPostMessage;

        var data = {
            action: prefixPostMessage + "sendJSCode",
            customData: jsCode
        };

        window.parent.postMessage(data, '*');
    },
    async havePreviousChat({ commit, dispatch, getters }, { refUser, idChatTemplate }) {
        var idChat = false;

        try {
            if (refUser != null && typeof refUser != "undefined") {
                const response = await axios.post(getters["envParams"].urlBE + '/chat/havePreviousChat', {
                    refUser: refUser,
                    idCT: idChatTemplate
                });

                if (response.data.isSuccess) {
                    idChat = response.data.data;
                }
            }
        } catch (err) {
            console.debug("havePreviousChat error : ", err);
            idChat = false;
        }

        if (idChat != false) {
            commit("doMaxSpeed", true);
        }

        return idChat;
    },
    async setHavePreviousChat({ state, getters }, { refUser, idChatTemplate, idChat, tsExpires }) {
        try {
            if (refUser != null && typeof refUser != "undefined"
                && idChatTemplate != null && typeof idChatTemplate != "undefined"
                && idChat != null && typeof idChat != "undefined") {

                const response = await axios.post(getters["envParams"].urlBE + '/chat/setHavePreviousChat', {
                    refUser: refUser,
                    idCT: idChatTemplate,
                    idChat: idChat,
                    tsExpires: tsExpires
                });

            }
        } catch (err) {
            console.debug("setHavePreviousChat error : ", err);
        }
    },

    initTemplating({ getters, commit }) {
        var chatParams = getters["chatParams"];
        var templatingData = {};

        if (Object.prototype.hasOwnProperty.call(chatParams, "assistantName")) {
            templatingData["name"] = chatParams.assistantName;
        } else {
            templatingData["name"] = "Zaral";
        }

        commit("templatingData", templatingData);
    },

    initUI({ getters, commit, dispatch }) {
        var chatParams = getters["chatParams"];
        var templatingData = {};

        if (Object.prototype.hasOwnProperty.call(chatParams, "profileImgUrl")) {
            commit("profileImgUrl", chatParams.profileImgUrl);
        }
        if (Object.prototype.hasOwnProperty.call(chatParams, "css")) {
            commit("css", chatParams.css);
        }

        dispatch("initFavicon");
    },

    initFavicon({ getters }) {
        let url = getters.profileImgUrl;

        let favicon = document.querySelector('link[rel="icon"]');
        if (!favicon) {
            favicon = document.createElement('link');
            favicon.setAttribute('rel', 'icon');
            favicon.setAttribute('type', 'image/png');
            document.head.appendChild(favicon);
        }
        favicon.setAttribute('href', url);
    },
    hideKeyboard(element) {
        element.attr('readonly', 'readonly'); // Force keyboard to hide on input field.
        element.attr('disabled', 'true'); // Force keyboard to hide on textarea field.
        setTimeout(function () {
            element.blur();  //actually close the keyboard
            // Remove readonly attribute after keyboard is hidden.
            element.removeAttr('readonly');
            element.removeAttr('disabled');
        }, 100);
    }

};

export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    strict: true
};
