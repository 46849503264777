import Vue              from 'vue'
import Vuex             from 'vuex';
import chatassistant from './chatassistant';


Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    chatassistant,
  },
});

export default store;


