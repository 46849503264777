<template>
  <div class="chat-button-container">
   <span class="chat-button-header" v-html="message.action.header"></span>
    <div class="chat-button" @click="handlerClick(message.action.js)">
        <span class="chat-button-title" v-html="message.action.text"></span>
        <span class="chat-button-sub-title" v-html="message.action.subText"></span>
    </div>
    <div class="chat-button-sub-button" v-html="message.action.subButton" @click="handlerClick(message.action.jsSubButton)"></div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
    lastMessage: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
        action: {},
    };
  },
  computed: {
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
        this.$emit("completed", true);
    },
    ...mapActions({
      sendJSCode: 'chatassistant/sendJSCode',
    }),
    handlerClick (code) {
        if (code != "" && code != null && typeof code != "undefined") {
            this.sendJSCode(code);
        }
    }
  }
};
</script>

<style scoped lang="scss">
    @import '../layout/v1/css/general.scss';

    .chat-button-container {
        margin: 20px auto 20px auto;
        display:flex;
        flex-direction:column;

        .chat-button-header {
            margin: auto;
            padding: 16px 28px 16px 28px;
            color:red;
            width: fit-content;
            font-size:1.1rem;
            text-align:center;
        }

        .chat-button {
            margin: auto;
            padding: 16px 28px 16px 28px;
            max-width: 90%;
            display:flex;
            flex-direction:column;
            background-color:#FF006B;
            border:2px solid rgba(0,0,0,0.2);
            border-radius: 3px;
            color:white;
            cursor: pointer;
            width: fit-content;
            box-shadow: 1px 1px 0px rgba(0,0,0,0.5);

            .chat-button-title {
              margin:auto;
              padding: 0;
              word-wrap: break-word;
              width: fit-content;
              font-size:1.9rem;
              font-weight: bold;
              text-align:center;
              text-shadow:1px 1px 0px rgba(0,0,0,0.5);
            }

            .chat-button-sub-title {
              margin:auto;
              padding: 0;
              word-wrap: break-word;
              width: fit-content;
              font-size:1.1rem;
              text-align:center;
            }
        }

        .chat-button-sub-button {
            padding:0;
            margin:20px auto;
            color:black;
            width: fit-content;
            font-size:1rem;
            text-align:center;
        }
    }
</style>