import Vue              from 'vue'
import Router           from 'vue-router'
import Chat             from '@/views/chatView.vue'

Vue.use(Router)

export default new Router({
    routes: [
      // Route to load chat in waiting mode and one time load (using cookie)
      {
          path: '/',
          name: 'ChatWaiting',
          component: Chat,
          props: { nol: false },
      },
      // Route to load chat in waiting mode with no usage limitation
      {
        path: '/cchat',
        name: 'ChatWaitingNoL',
        component: Chat,
        props: { nol: true },
      },
      {
        path: '/:propIDChatTemplate',
        name: 'Chat',
        component: Chat,
        props: true,
      },
      {
        path: '/widget/:tagline/:propIDChatTemplate',
        name: 'Widget',
        component: Chat,
        props: route => ({
                 tagline: route.params.tagline,
                 propIDChatTemplate: route.params.propIDChatTemplate,
                 widget: true,
               })
      },
      {
        path: '/chat/:propIDChat',
        name: 'ExistingChat',
        component: Chat,
        props: true,
      },
      {
        path: '/chats/:propIDChat/:speed',
        name: 'ExistingChatSpeed',
        component: Chat,
        props: true,
      }
    ]
})

