<template>
  <div class="chat-button-container" v-if="false">
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
    lastMessage: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
        action: {},
    };
  },
  computed: {
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
        this.handlerJS(this.message.action.js);
        this.$emit("completed", true);
    },
    ...mapActions({
      sendJSCode: 'chatassistant/sendJSCode',
    }),
    handlerJS (code) {
        if (code != "" && code != null && typeof code != "undefined") {
            this.sendJSCode(code);
        }
    }
  }
};
</script>
<style scoped lang="scss">
</style>