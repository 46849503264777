import Vue              from 'vue'
import App              from './App.vue'
import Vuex             from 'vuex'
import store            from '@/store/index'
import router           from './router'
import VueI18n          from 'vue-i18n'
import VueCookies       from 'vue-cookies'

import textEN           from './layout/v1/lang/en'
import dateTimeFormats  from './layout/v1/lang/dateTimeFormats'
import numberFormats    from './layout/v1/lang/numberFormats'

Vue.use(VueI18n)

import vuetify from './plugins/vuetify';

var locale = 'en';
var messages = textEN;

const i18n = new VueI18n({
    fallbackLocale: 'en',
    locale: locale,
    messages,
    dateTimeFormats,
    numberFormats
})


Vue.use(VueCookies, { expires: '90d'});

new Vue({
  store,
  locale: locale,
  el: '#app',
  router,
  template: '<App/>',
  i18n,
  vuetify,
  render: h => h(App)
})
