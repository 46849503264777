<template>
	<div class="chatassistant">
		<div class="messages" ref="messages">
			<FactoryElement
				v-for="(message, index) in messagesToShow"
				:key="index"
				:message="message"
				:lastMessage="lastMessage(message)"
				:index="index"
				@completed="messageCompleted"
				@scrollToBottom="scrollToBottom"
			/>
			<div class="typing" v-if="waitAnswer">
				<div class="typing__dot"></div>
				<div class="typing__dot"></div>
				<div class="typing__dot"></div>
			</div>
			<div ref="bottom"></div>

			<v-skeleton-loader
				type="sentences, sentences"
				v-if="messagesToShow.length == 0"
				class="skeletonChat"
			></v-skeleton-loader>
		</div>

		<form @submit.prevent="callSendMessage" class="textForm">
			<input type="text" v-model="newMessage" ref="userMessage" />
			<v-btn type="submit" icon><v-icon size="x-large">mdi-send</v-icon></v-btn>
		</form>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import FactoryElement from './factoryElement.vue';

export default {
	components: {
		FactoryElement,
	},
	props: {},
	data() {
		return {
			newMessage: '',
			idInterval: null,
			dailyMessagesExceeded: false,
			messagesToShow: [],
			messagesTreated: [],
			showMessageInProgress: false,
		};
	},
	computed: {
		...mapGetters({
			messages: 'chatassistant/messages',
			lastTS: 'chatassistant/lastTS',
			waitAnswer: 'chatassistant/waitAnswer',
			chatStatus: 'chatassistant/chatStatus',
			chatClosed: 'chatassistant/chatClosed',
		}),
	},
	watch: {
		lastTS(newVal) {
			this.$nextTick(() => {
				this.addNewMessage();
			});
		},
		waitAnswer(newVal) {
			this.$nextTick(() => {
				if (newVal) {
					this.scrollToBottom();
				}
			});
		},
		chatStatus(newVal) {
			if (newVal == 'STARTED' && this.idInterval == null) {
				this.idInterval = setInterval(this.callGetMessages, 6000);
			}
		},
	},
	mounted() {
		this.init();
	},
	methods: {
		// Not the place
		launchChat(data) {
			try {
				var customData = data.customData;
				this.$store.commit('chatassistant/customData', customData);

				var refUser = null;
				try {
					refUser = data.refUser;
					this.$store.commit('chatassistant/refUser', refUser);
				} catch (err) {
					console.debug('launchChat error : ', err);
				}

				// Last data to update because it's used by over components to start chat
				var idChatTemplate = data.idChatTemplate;
				this.$store.commit('chatassistant/idChatTemplate', idChatTemplate);
			} catch (err) {
				console.error(err);
			}
		},

		init() {
			if (this.messages.length > 0) {
				this.addNewMessage();
			}

            if (this.chatStatus == 'STARTED' && this.idInterval == null) {
                this.idInterval     = setInterval(this.callGetMessages, 6000);
			}
		},
		...mapMutations({
			waitAnswerSet: 'chatassistant/waitAnswer',
		}),
		...mapActions({
			getMessages: 'chatassistant/getMessages',
			sendMessage: 'chatassistant/sendMessage',
			hideKeyboard: 'chatassistant/hideKeyboard',
		}),
		async callSendMessage() {
			if (this.waitAnswer) {
				this.$snackbar.tAdd({
					text: 'chatAssistant.waitForAnswer',
					type: 'warning',
					delay: 5000,
				});
				return;
			}
			if (this.dailyMessagesExceeded) {
				this.$snackbar.tAdd({
					text: 'chatAssistant.dailyMessagesExceeded',
					type: 'warning',
					delay: 10000,
				});
				return;
			}
			if (this.chatStatus == 'COMPLETED') {
				this.$snackbar.tAdd({
					text: 'chatAssistant.chatCompleted',
					type: 'warning',
					delay: 10000,
				});
				return;
			}

			if (this.newMessage.trim().length === 0) {
				return;
			}

			// Add the user's message to the store
			this.sendMessage({ message: this.newMessage, fromUser: true });

			// Clear the input field
			this.newMessage = '';

			this.hideKeyboard(this.$refs['userMessage']);
		},

		async callGetMessages() {
			await this.getMessages();
		},
		scrollToBottom() {
			this.$nextTick(() => {
				//this.$refs["bottom"].scrollIntoView({ behavior: "smooth" });
				var div = this.$refs['messages'];
				div.scrollTo({
					top: div.scrollHeight,
					behavior: 'smooth',
				});
			});
		},
		messageCompleted(haveToStopWaitAnswer) {
			console.log('messageCompleted : ', haveToStopWaitAnswer);
			//this.$refs["userMessage"].focus();
			this.showMessageInProgress = false;
			if (haveToStopWaitAnswer) {
				this.waitAnswerSet(false);
			}
			this.addNewMessage();
		},
		addNewMessage() {
			if (
				!this.showMessageInProgress &&
				this.messages.length > this.messagesTreated.length
			) {
				var message = this.messages[this.messagesTreated.length];
				this.messagesTreated.push(message);

				this.waitAnswerSet(true);

				if (
					message.type != '10' ||
					message.action.action == 'BUTTON' ||
					message.action.action == 'CMD'
				) {
					this.showMessageInProgress = true;
					this.messagesToShow.push(message);
				} else {
					this.addNewMessage();
				}
			} else {
				// after a chat retrieve => typing speed become normal
				this.$store.commit('chatassistant/doMaxSpeed', false);

				if (
					this.messages.length == this.messagesTreated.length &&
					this.chatClosed
				) {
					this.$store.commit('chatassistant/showChatClosed', true);
					this.waitAnswerSet(false);
				}
			}
			this.scrollToBottom();
		},
		lastMessage(message) {
			var lastMessage = false;
			for (var i = this.messagesToShow.length; i > 0; i--) {
				if (
					(message.fromUser && this.messagesToShow[i - 1].fromUser) ||
					(message.fromBot && this.messagesToShow[i - 1].fromBot)
				) {
					if (message.idMessage == this.messagesToShow[i - 1].idMessage) {
						lastMessage = true;
					}
					break;
				}
			}
			return lastMessage;
		},
	},
	//beforeDestroy () {
	beforeUnmount() {
		try {
			if (this.idInterval != null) {
				clearInterval(this.idInterval);
			}
		} catch (err) {
			console.error('chatassistant.beforeUnmount error : ', err);
		}
	},
};
</script>

<style scoped lang="scss">
@import '../layout/v1/css/general.scss';

.chatassistant {
	display: flex;
	flex-direction: column;
	height: calc(100% - 80px);
	width: 100%;
	position: relative;
	background-color: #f7f7f7;

	h2 {
		margin-top: 0;
	}

	.messages {
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		padding: 7px;
		background-color: $color-bg-messages;
	}

	form {
		display: flex;
		margin-top: auto;
		margin: 5px 9px;

		input[type='text'] {
			flex: 1;
			padding: 0.5rem;
			border-radius: 0.5rem;
			border: solid 2px $color-encart;
			margin-right: 0.5rem;
			font-size: 1.1rem;
			color: black;
		}

		button[type='submit'] {
			background-color: $color-button-ok;
			color: #fff;
			border: none;
			padding: 0.5rem 1rem;
			font-size: 1rem;
			font-weight: bold;
			cursor: pointer;
			transition: background-color 0.2s;
			margin-top: 3px;

			&:hover {
				background-color: $color-button-ok-hover;
			}
		}
	}
}

.skeletonChat {
	margin: 10px;
	width: 80%;
	height: 100%;
	z-index: 10;
}

.typing {
	width: 5em;
	height: 2em;
	position: relative;
	padding: 10px;
	margin-left: 5px;
	background: #e6e6e6;
	border-radius: 20px;
}

.typing__dot {
	float: left;
	width: 10px;
	height: 10px;
	margin: 0 4px;
	background: #8d8c91;
	border-radius: 50%;
	opacity: 0;
	animation: loadingFade 2s infinite;
	background-color: gray !important;
}

.typing__dot:nth-child(1) {
	animation-delay: 0s;
}

.typing__dot:nth-child(2) {
	animation-delay: 0.5s;
}

.typing__dot:nth-child(3) {
	animation-delay: 1s;
}

@keyframes loadingFade {
	0% {
		opacity: 0;
	}
	33% {
		opacity: 0.8;
	}
	66% {
		opacity: 0;
	}
}
</style>
