<template >
    <div class="notifContainer ml-auto">
        <div v-for="(notif, index) in notifs"
             :key="index">
            <v-alert dense
                     :type="notif.type"
                     class="my-1"
                     v-html="$sanitize(notif.text)"
            >
            </v-alert>
        </div>
    </div>
</template>

<script>
import Vue        from 'vue'

export default {
  name: 'VSnackBar',
  components: {
  },
  props: {
  },
  data: function () {
    return {
        notifs: [],
        acceptedType: ['success', 'warning', 'info', 'error'],
    }
  },
  computed: {
  },
  created: function () {
    this.init();
  },
  mounted: function () {
  },
  methods: {
    init() {
        // Ajout de socketIO Controller dans l'instance
        Vue.prototype.$snackbar = this;
    },

    add(notif) {
        if (!this.acceptedType.includes(notif.type)) {
            notif.type = 'info';
        }

        this.notifs.push(notif);

        var delay = 4000;

        try {
            if (Number.isInteger(notif.delay)
                && notif.delay > 0 && notif.delay < 20000) {
                delay = notif.delay;
            }
        } catch (err) {
            console.log.debug("vSnackbar.error ", err);
        }

        setTimeout(this.removeFirstNotif, delay);
    },
    tAdd(notif) {
        notif.text = this.$t(notif.text);
        this.add(notif);
    },
    removeFirstNotif() {
        this.notifs.shift();
    },
  }
}
</script>
<style scoped>
    .notifContainer {
        position: fixed;
        top: 100px;
        right: 10px;
        padding: 5px;
        max-width: 40%;
        z-index:10000;
    }

</style>