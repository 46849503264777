<template>
	<div>
		<div v-if="showChat" class="chat-container">
			<div class="chat-header">
				<div class="niv1">
					<div v-if="mode == 'dev'">DEV</div>
					<v-avatar>
						<v-img :src="profileImgUrl"></v-img>
					</v-avatar>
					<div class="niv2">
						<h1>{{ localTemplateT($t('chatAssistant.title')) }}</h1>
						<div class="subTitleContainer">
							<h2>{{ subTitle }}</h2>
							<div class="on" :class="statusButton">&nbsp;</div>
						</div>
					</div>
					<div v-if="false" class="niv3">
						<v-menu>
							<template v-slot:activator="{ props }">
								<v-btn icon v-bind="props">
									<v-icon v-bind="props">mdi-dots-vertical</v-icon>
								</v-btn>
							</template>

							<v-list>
								<v-list-item v-for="(item, i) in items" :key="i">
									<v-list-item-title>{{ item.title }}</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</div>
				</div>
			</div>
			<div class="floatingRight">
				<v-btn icon @click="closeChat" class="closeBtn">
					<v-icon x-large color="white">mdi-close</v-icon>
				</v-btn>
			</div>
			<chatComponent ref="chat"></chatComponent>
		</div>

		<div v-else class="widget" @click="openChat">
			<WidgetElement :tagline="tagline" />
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import ChatComponent from '../components/chatComponent.vue';
import WidgetElement from '../components/widgetElement.vue';
import { Store } from '../tools/Store.js';
import { VBtn } from 'vuetify/lib';

export default {
	components: {
		ChatComponent,
		WidgetElement,
	},
	props: {
		propIDChat: {
			type: String,
			required: false,
			default: '',
		},
		propIDChatTemplate: {
			type: String,
			require: false,
			default: '',
		},
		nol: {
			type: Boolean,
			require: false,
			default: false,
		},
		speed: {
			type: String,
			require: false,
			default: '',
		},
		widget: {
			type: Boolean,
			require: false,
			default: false,
		},
		tagline: {
			type: String,
			require: false,
			default: "0",
		},
	},
	data() {
		return {
			inputValue: '',
			canInitChat: null,
			showChat: false,
			items: [
				{ title: 'Click Me1' },
				{ title: 'Click Me2' },
				{ title: 'Click Me3' },
			],
		};
	},
	computed: {
		...mapGetters({
			profileImgUrl: 'chatassistant/profileImgUrl',
			idChatTemplate: 'chatassistant/idChatTemplate',
			idChat: 'chatassistant/idChat',
			showChatClosed: 'chatassistant/showChatClosed',
			mode: 'chatassistant/mode',
			refUser: 'chatassistant/refUser',
			envParams: 'chatassistant/envParams',
			templateT: 'chatassistant/templateT',
			chatInitialized: 'chatassistant/chatInitialized',
		}),
		subTitle() {
			return this.showChatClosed
				? this.$t('chatAssistant.subTitleOffline')
				: this.$t('chatAssistant.subTitle');
		},
		statusButton() {
			return this.showChatClosed ? 'offColor' : 'onColor';
		},
	},
	watch: {
		idChatTemplate(newVal) {
			if (newVal != this.propIDChatTemplate
                || this.propIDChatTemplate != "" && this.idChat == "") {
				this.$nextTick(() => {
				console.debug("Dans chatView watch.idChatTemplate 3 ");
					this.init(newVal);
				});
			}
		},
	},
	mounted() {
		this.initApp();

		if (this.speed == '1') {
			this.$store.commit('chatassistant/doMaxSpeed', true);
		}
        if (this.propIDChat != '') {
            this.checkAndRetrieveChat(this.propIDChat);
        } else {
            if (this.widget) {
                this.getTemplateParams(this.propIDChatTemplate);
            } else {
                this.init(this.propIDChatTemplate);
            }
        }
        //this.closeChat();
	},
	methods: {
		async init(idChatTemplate) {
			if (idChatTemplate != '') {
				this.$store.commit('chatassistant/idChatTemplate', idChatTemplate);

				var refUser = this.refUser;
				var idChat = await this.havePreviousChat({ refUser, idChatTemplate });
				if (idChat) {
					this.checkAndRetrieveChat(idChat);
				} else {
					if (this.canInit(idChatTemplate)) {
						await this.createChat(idChatTemplate);

						this.showChat = true;
						this.getMessages();
					} else {
						this.$snackbar.tAdd({
							text: 'chatAssistant.onlyOneUse',
							type: 'warning',
							delay: 10000,
						});
						this.closeChat();
					}
				}
			}
		},

		canInit(idChatTemplate) {
			if (this.canInitChat != null) {
				return this.canInitChat;
			}
			if (!this.nol) {
				try {
					var canInitChat = Store.get(Store.createKey('cic', idChatTemplate));
					this.canInitChat = canInitChat;
				} catch (err) {
					this.canInitChat = true;
				}

				//Store.set(Store.createKey("cic", idChatTemplate), false);
			} else {
				this.canInitChat = true;
			}

			return this.canInitChat;
		},

		checkAndRetrieveChat(idChat) {
			this.retrieveChat(idChat);
		},
		openChat() {
            let data        = {
                            customData: {},
                            refUser: null,
                            idChatTemplate: this.propIDChatTemplate
                            };
			this.showChat   = true;
			this.$nextTick(() => {
                this.$refs.chat.launchChat(data);
			});
			this.$store.commit('chatassistant/chatClosed', false);
		},
		closeChat() {
			this.showChat = false;
			this.$store.commit('chatassistant/chatClosed', true);
			this.$emit('close');
		},
		...mapActions({
			createChat: 'chatassistant/createChat',
			retrieveChat: 'chatassistant/retrieveChat',
			getMessages: 'chatassistant/getMessages',
			startChat: 'chatassistant/startChat',
			initApp: 'chatassistant/initApp',
			havePreviousChat: 'chatassistant/havePreviousChat',
			getTemplateParams: 'chatassistant/getTemplateParams',
		}),
		localTemplateT(message) {
			return this.chatInitialized ? this.templateT(message) : '';
		},
	},
};
</script>

<style scoped lang="scss">
@import '../layout/v1/css/general.scss';

.chat-container {
	height: 100%;
	width: 100%;
	margin: 0;
	top: 0;
	left: 0;
	font-family: $font-family;
	position: absolute;
}

.chat-header {
	width: 100%;
	padding: 16px;
	font-size: 24px;
	font-weight: bold;
	background-color: $color-encart;
	color: $color-police-title;

	.niv1 {
		display: flex;
		flex-direction: row;

		div {
			margin: 0 5px;
		}
	}
	.niv2 {
		display: flex;
		flex-direction: column;

		.subTitleContainer {
			display: flex;
			flex-direction: row;
			margin: 0;

			h2 {
				margin: 0;
			}
		}
	}

	.niv3 {
		margin: 0 0 0 auto !important;
		z-index: 10;
	}
}
.chat-header h1 {
	font-size: 1em;
}
.chat-header h2 {
	font-size: 0.6em;
	color: $color-police-subTitle;
	font-weight: normal;
}

.floatingRight {
	right: 0px;
	top: 0px;
	position: absolute;
	z-index: 2;
	width: 36px;
	height: 36px;
	margin: 12px 12px 12px 0;
}

.on {
	border-radius: 50%;
	width: 15px;
	height: 15px;
	margin-top: 2px !important;
}
.onColor {
	background-color: #12ba12;
}
.offColor {
	visibility: hidden;
}
</style>
