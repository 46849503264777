<template>
  <div>
    <ChatMessage
        :message="message"
        :lastMessage="lastMessage"
        @completed="completed"
        @scrollToBottom="scrollToBottom"
        v-if="elementSelector == type.MESSAGE"
      />
    <ChatButton
        :message="message"
        :lastMessage="lastMessage"
        @completed="completed"
        @scrollToBottom="scrollToBottom"
        v-if="elementSelector == type.BUTTON"
      />
    <ChatCmd
        :message="message"
        :lastMessage="lastMessage"
        @completed="completed"
        @scrollToBottom="scrollToBottom"
        v-if="elementSelector == type.CMD"
      />
  </div>
</template>
<script>
import ChatMessage  from './messageElement.vue';
import ChatButton   from './buttonElement.vue';
import ChatCmd      from './cmdElement.vue';

export default {
  components: {
    ChatMessage,
    ChatButton,
    ChatCmd
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    lastMessage: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
        type: {
                "MESSAGE":  "MESSAGE",
                "BUTTON":   "BUTTON",
                "CMD":      "CMD"
              },
    };
  },
  computed: {
    elementSelector () {
        if (this.message.type == "10") {
            return this.message.action.action;
        } else {
            return this.type.MESSAGE;
        }
    }
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
    },
    completed (haveToStopWaitAnswer) {
        this.$emit("completed", haveToStopWaitAnswer);
    },
    scrollToBottom () {
        this.$emit("scrollToBottom");
    }
  }
};
</script>
<style scoped lang="scss">
    @import '../layout/v1/css/general.scss';
</style>