import CryptoJS     from 'crypto-js'

const Store = {
    c: null,
    get (key) {
      var value = Store.getTechnical(key);
      var bytes = CryptoJS.AES.decrypt(value, 'SDzdetT@R3!4');
      return bytes.toString(CryptoJS.enc.Utf8);
    },
    set (key, value, expires = "90d") {
      try {
        value = CryptoJS.AES.encrypt(value, 'SDzdetT@R3!4');
        return Store.setTechnical(key, value.toString(), expires);
      } catch (err) {
      console.log("cookie error : ", err);
        return null;
      }
    },
    valid () {
        return true;
    },
    createKey (prefix, sufix) {
        var md5 = require('blueimp-md5');
        var value   = md5(sufix +"sdgdEZGZG4");
        return prefix +"_" +value;
    },
    getTechnical (key) {
        return Store.lgetTechnical (key);
    },
    setTechnical (key, value, expires) {
        Store.lsetTechnical(key, value, expires);
    },
    lgetTechnical (key) {
        return localStorage[key];
    },
    lsetTechnical (key, value, expires) {
        localStorage[key]   = value.toString();
    },
    cgetTechnical (key) {
        return window.$cookies.get(key);
    },
    csetTechnical (key, value, expires) {
        window.$cookies.set(key, value.toString(), expires);
    },

}

export { Store }