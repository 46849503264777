<template>
	<div class="widget">
		<div class="top">
			<div class="avatar">
				<v-avatar>
					<v-img :src="profileImgUrl"></v-img>
				</v-avatar>
			</div>
			<div class="bubble">{{ getTagline }}</div>
		</div>
		<div class="bottom">
			<div class="inputContainer">
				<input type="text" />
				<div class="caret"></div>
				<v-btn type="submit" icon>
					<v-icon size="x-large">mdi-send</v-icon>
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	components: {},
	props: {
		tagline: {
			type: String,
			require: false,
			default: "0",
		},
	},
	data() {
		return {};
	},
	computed: {
		...mapGetters({
			profileImgUrl: 'chatassistant/profileImgUrl',
			taglineArray: 'chatassistant/tagline',
		}),
		getTagline () {
			const tagline = isNaN(this.tagline) ? 0 : this.tagline;
			return this.taglineArray[tagline];
		},
	}
};
</script>

<style scoped lang="scss">
@import '../layout/v1/css/general.scss';

.widget {
	position: fixed;
	bottom: 0;
	right: 0;
	width: 100%;
	padding: 5px 10px;
	background-color: rgb(255, 255, 255);
	overflow: hidden;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	overflow: hidden;

	.top {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		gap: 15px;

		.avatar {
			border: 1px solid rgb(44, 44, 44);
			border-radius: 100%;
		}
		.bubble {
			position: relative;
			border: 2px solid $color-encart-priority;
			border-radius: 10px;
			padding: 10px;

			// bottom left triangle corner
			&:before {
				content: '';
				position: absolute;
				top: 9px;
				left: -21px;
				width: 0;
				height: 0;
				border: 10px solid transparent;
				border-top-color: $color-encart-priority;
				transform: rotate(90deg);
			}
			&:after {
				content: '';
				position: absolute;
				top: 9px;
				left: -18px;
				width: 0;
				height: 0;
				border: 10px solid transparent;
				border-top-color: rgb(255, 255, 255);
				transform: rotate(90deg);
			}
		}
	}

	.bottom {
		width: 100%;
		.inputContainer {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-top: 10px;
			position: relative;

			.caret {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 10px;
				width: 1px;
				height: 70%;
				border-left: 2px solid $color-encart;
				animation: blink 1s infinite ease-in-out;
			}

			input {
				padding: 0.5rem;
				border-radius: 0.5rem;
				border: 2px solid $color-encart;
				width: calc(100% - 40px);
				margin-right: 10px;
				position: relative;
			}

			button {
				background-color: $color-button-ok;
				color: #fff;
				border: none;
				padding: 0.5rem 1rem;
				font-size: 1rem;
				font-weight: bold;
				cursor: pointer;
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.widget {
		width: 400px;
		height: 500px;
	}
}

@keyframes blink {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
</style>
