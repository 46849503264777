export default {
  'en': {
        chatAssistant: {
            title: "__name__",
            subTitle: "Online",
            subTitleOffline: "Offline",
            btnSend: "Send",
            waitForAnswer: "Please wait for __name__ answer",
            dailyMessagesExceeded: "Daily exchange exceeded",
            onlyOneUse: "You have already done this chat. You can't do it twice. Thank you for your understanding.",
            chatCompleted: "Thank you for this chat. I hope I give you some good answer. Now, I had to go.",
        }
  }
}