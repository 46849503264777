import { render, staticRenderFns } from "./chatView.vue?vue&type=template&id=00ee86eb&scoped=true"
import script from "./chatView.vue?vue&type=script&lang=js"
export * from "./chatView.vue?vue&type=script&lang=js"
import style0 from "./chatView.vue?vue&type=style&index=0&id=00ee86eb&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00ee86eb",
  null
  
)

export default component.exports