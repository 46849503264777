<template>
  <div>
   <div class="message"
    :class="{ 'from-user': message.fromUser, 'from-bot': message.fromBot }"
    v-for="(onePartMessage, index) in messageAllPartToShow"
    :key="index"
    :index="index"
    >
        <div class="niv1">
            <div class="avatarContainer"
                v-if="message.fromBot">
                <v-avatar
                    v-if="onePartMessage.lastMessage && lastMessage">
                  <v-img
                    :src="profileImgUrl"
                    size="small"
                  ></v-img>
                </v-avatar>
            </div>
            <div class="niv2">
                <div class="message-text" v-if="index != currentIndex || show">{{ onePartMessage.message }}</div>
                <div class="message-timestamp" v-if="timestamp != ''">{{ timestamp }}</div>
            </div>
            <div class="avatarContainer"
                v-if="message.fromUser && false">
                <v-avatar
                    v-if="lastMessage">
                  <v-img
                    :src="userProfileImgUrl"
                    size="small"
                  ></v-img>
                </v-avatar>
            </div>
        </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
    lastMessage: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      messageAllPart: [],
      messageAllPartToShow: [],
      currentIndex: 0,
    };
  },
  computed: {
    timestamp() {
      var tsStr     = "";
      var ts        = this.message.timestamp;
      if (typeof ts !== 'undefined') {
          const date    = new Date(ts);
          const hours   = date.getHours();
          const minutes = date.getMinutes();
          tsStr = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      }

      return  tsStr;
    },
    ...mapGetters({
        speedTyping:        'chatassistant/speedTyping',
        doMaxSpeed:         'chatassistant/doMaxSpeed',
        profileImgUrl:      'chatassistant/profileImgUrl',
        userProfileImgUrl:  'chatassistant/userProfileImgUrl',
        messageSplit:       'chatassistant/messageSplit'
    }),

  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
        if (this.message.fromUser) {
            this.messageAllPartToShow.push(this.message);
            this.show               = true;

            this.$emit("completed", false);
        } else {
            var messageClean        = this.message.message.replace(/(\t|\r)/gm, "");

            var messageAllPart      = [];

            if (this.messageSplit.type == "SPLIT_PER_SENTENCE") {
                messageAllPart      = this.splitPerSentences(messageClean);
            } else {
                messageAllPart      = this.splitPerWords(messageClean, this.messageSplit.nbChar);
            }

            this.messageAllPart = messageAllPart;

            this.startAnim();
        }
    },
    splitPerSentences (messageClean, minSentenceLength = 5) {
        var sentences           = messageClean.match(/[^.!?\n]+[.!?\n]+/g);

        sentences               = (sentences == null ? [messageClean]: sentences);

        var messageAllPart      = [];
        var i                   = null;

        for (var message of sentences) {
            i       = null;

            message = message.replace(/(\r\n|\n)/gm, "");
            message = message.trim();
            if (message.length <= minSentenceLength && messageAllPart.length > 0) {
                i   = messageAllPart.length -1;
            }
            if (i == null) {
                messageAllPart.push({message: message});
            } else {
                messageAllPart[i].message   += message;
            }
        }

        return messageAllPart;
    },
    splitPerWords (messageClean, nbChar) {
        var messageAllPart      = [];
        var sentences           = this.splitPerSentences(messageClean, 0);

        for (var oneMessage of sentences) {
            let message         = oneMessage.message;

            let start = 0;
            while (start < message.length) {
                let end = start + nbChar;
                if (end >= message.length) {
                    end = message.length;
                } else if (message[end] !== ' ') {

                    while (end < message.length && message[end] !== ' ') {
                        end++;
                    }

                    if (end >= message.length) {
                        end = message.length;
                    }
                }

                messageAllPart.push({message: message.slice(start, end)});
                start = end + 1;
            }
        }

        return messageAllPart;
    },
    startAnim () {
        this.show       = false;

        var typingDelay     = 1000; //3000; // default value;
        var indexForSpeed   = this.currentIndex -1;

        try {
            if (indexForSpeed >= 0) {
                var messageForSpeed = this.messageAllPart[indexForSpeed];
                var words           = messageForSpeed.message.split(" ");
                var speedTyping     = (this.doMaxSpeed ? 10000 : this.speedTyping);
                typingDelay         = words.length *60000/speedTyping;
                typingDelay         = (typingDelay > 10000 ? 10000 : typingDelay);
            }
        } catch (err) {
            typingDelay             = 2000;
        }

        var message     = this.messageAllPart[this.currentIndex];
        this.messageAllPartToShow.push(message);
        setTimeout(this.stopAnim, typingDelay);

        this.$emit("scrollToBottom");
    },
    stopAnim () {
        this.showProfileToCurrentMessage();

        this.show           = true;
        this.currentIndex   += 1;

        if (this.currentIndex == this.messageAllPart.length) {
            this.$emit("completed", true);
        } else {
            this.startAnim();
        }
    },
    showProfileToCurrentMessage () {
        try {
            var lastMessage             = null;
            if (this.currentIndex >= 1) {
                lastMessage             = this.messageAllPartToShow[this.currentIndex -1];
                lastMessage.lastMessage = false;
            }

            lastMessage             = this.messageAllPartToShow[this.currentIndex];
            lastMessage.lastMessage = true;
        } catch (err) {
            console.log("showProfileToCurrentMessage : ", err, this.currentIndex);
        }
    }
  }
};
</script>

<style scoped lang="scss">
    @import '../layout/v1/css/general.scss';

.avatarContainer {
    width:48px;
}
.message {
  width: 100%;
  margin-bottom: 8px;
  position: relative;
  color: black;


    .niv1 {
      max-width: 90%;
      width: fit-content;
      display:flex;
      flex-direction:row;

      div {
          margin: 0 5px 0 0;
      }
    }
    .niv2 {
      display:flex;
      flex-direction:column;
      position: relative;

    }
}

.from-bot {
    .niv1 {
      margin: 0 auto 0 0;

      .niv2 {
        width: calc(100% - 53px);
      }

    }
}

.from-user {
    .niv1 {
      margin: 0 0 0 auto;

      .niv2 {
          width: 100%;
        }
    }
}

.message-text {
  padding: 16px 28px 16px 28px;
  word-wrap: break-word;
  width: fit-content;
  font-size:1.1rem;
}

.from-user .niv2 div {
    background-color: $color-encart;
    margin-left:auto;
    border-radius: 8px;
    border-radius: 8px 8px 0px 8px;
    color: white;
    line-height: 2.3em;
}
.from-bot .niv2 div {
    background-color: #fff;
    margin-right:auto;
    border-radius: 8px 8px 8px 0px;
    line-height: 2.3em;
}

.message-timestamp {
  font-size: 10px;
  margin-top: 4px;
  text-align: right;
}


.typing {
  width: 5em;
  height: 2em;
  position: relative;
  padding: 10px;
  margin-left: 5px;
  background: #e6e6e6;
  border-radius: 20px;
}

.typing__dot {
  float: left;
  width: 10px;
  height: 10px;
  margin: 0 4px;
  background: #8d8c91;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 2s infinite;
  background-color: gray !important;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.5s;
}

.typing__dot:nth-child(3) {
  animation-delay: 1s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0.8;
  }
  66% {
    opacity: 0;
  }
}
</style>