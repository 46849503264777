export default {
  'en': {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    veryShort: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    time: {
      hour: 'numeric', minute: 'numeric', hour12: true
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      hour: 'numeric', minute: 'numeric', hour12: true
    },
    onlyDate: {
      year: 'numeric', month: 'numeric', day: 'numeric'
    }
  },
  'fr': {
    short: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    veryShort: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    time: {
      hour: 'numeric', minute: 'numeric', hour12: false
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      hour: 'numeric', minute: 'numeric', hour12: false
    },
    onlyDate: {
      year: 'numeric', month: 'numeric', day: 'numeric'
    }
  }
}
